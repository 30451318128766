import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import PreOferta from '../components/pages/afi/PreOferta'

const TuOfertaAfiPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    pageContext={pageContext}
    location={location}
    currentStep={pageContext.step}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <PreOferta pageContext={pageContext} />
      </>
    )}
  />
)

export default TuOfertaAfiPage